<template>
    <div>
        <!-- Title and Logo -->
        <h-retribusi />
        <!-- end Logo and Title -->
        <panel
            title="List Surat Tanda Setoran Retribusi Daerah"
            class="panel panel-success"
        >

            <!-- BAGIAN TOOLS LIST -->
            <b-row>
                <b-col md="12">
                    <b-card border-variant="dark" header-tag="header" footer-tag="footer">
                        <template #header>
                            <div class="pull-right">
                                <b-button variant="primary" to="/menu/stsrd/buat">
                                    <i class="fa fa-plus pr-1"></i> Tambah STSRD
                                </b-button>
                            </div>
                            <h6 class="mb-0">Tools</h6>
                        </template>

                        <!-- FILTER 01 -->
                        <b-card-body class="p-0 m-0">
                            <b-row>
                                <b-col md="6">
                                    <b-form-group
                                        :label-cols="3"
                                        label="Filter By"
                                        class="mb-1"
                                    >
                                        <b-input-group size="md">
                                            <b-form-select
                                                v-model="filterBy"
                                                :options="fieldOptions"
                                                @change="changeFilterBy()"
                                            >
                                                <option slot="first" :value="'all'"
                                                    >All Data</option
                                                >
                                            </b-form-select>
                                        </b-input-group>
                                    </b-form-group>
                                    <b-form-group
                                        :label-cols="3"
                                        label="Filter"
                                        class="mb-0"
                                        description="Type to Search or Click Clear to Stop Searching "
                                    >
                                        <b-input-group size="md">
                                            <b-form-input
                                                v-model="filter"
                                                @keyup.enter="testFunc(filter)"
                                                placeholder="Type to Search"
                                                debounce="500"
                                            />
                                            <b-input-group-append>
                                                <b-btn
                                                    :disabled="!filter"
                                                    @click="filter = ''"
                                                    variant="info"
                                                    >Clear</b-btn
                                                >
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6">
                                    <b-form-group
                                        :label-cols="3"
                                        label="Sort"
                                        class="mb-1"
                                    >
                                        <b-input-group size="md">
                                            <b-form-select
                                                v-model="sortBy"
                                                :options="fieldOptions"
                                            >
                                                <option slot="first" :value="null">-- none --</option>
                                            </b-form-select>
                                            <b-form-select
                                                :disabled="!sortBy"
                                                v-model="sortDesc"
                                                slot="append"
                                            >
                                                <option :value="false">Asc</option>
                                                <option :value="true">Desc</option>
                                            </b-form-select>
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-card-body>
                        <!-- end FILTER 01 -->
                        
                        <!--
                        <div class="pull-right mx-0 my-0">
                            <b-button variant="primary" to="/menu/sptrd/pendaftaran">
                                <i class="fa fa-plus pr-1"></i> Tambah SPTRD
                            </b-button>
                        </div> -->
                        <template #footer>
                            <!-- <em>Footer Slot</em> -->
                            <div class="pull-right">
                                <b-button variant="primary" @click="reload">
                                    <i class="fa fa-sync-alt pr-1"></i> Refresh
                                </b-button>
                                <!-- <b-button class="ml-2" variant="primary" to="/menu/sptrd/pendaftaran">
                                    <i class="fa fa-plus pr-1"></i> Tambah SPTRD
                                </b-button> -->
                            </div>
                        </template>
                    </b-card>
                </b-col>
            </b-row>
            <!-- end BAGIAN TOOLS LIST -->

            <hr class="my-2">

            <!-- Data Table & Pagination -->
            <b-table
                show-empty
                small
                id="masTable"
                ref="masTable"
                head-variant="light"
                :hover="true"
                :busy.sync="isBusy"
                :items="myGetData"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                bordered
            >
                <!-- PENGATURAN KOLOM 
                -------------------------------------------------- -->
                <template v-slot:table-busy>
                    <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                    </div>
                </template>
                <!-- ... -->
                <template v-slot:table-colgroup="scope">
                    <col
                        v-for="field in scope.fields"
                        :key="field.key"
                        :style="{
                            width: field.key === 'status_bayar' ? '80px' :
                                field.key === 'actions' ? '105px' : ''
                        }"
                    />
                </template>
                <template #head()="data">
                    <div class="" v-html="data.label"></div>
                </template>
                <!-- ... -->
                <template #cell(index)="data">
                    {{ data.index + 1 }}
                </template>
                <!-- PENGATURAN KOLOM 
                -------------------------------------------------- -->

                <template #cell(spt)="data">
					<div v-if="data.item.perangkat_pd">
						{{ data.item.perangkat_pd.nama }}
					</div>
					<div v-if="data.item.unit_pd">
						{{ data.item.unit_pd.nama }}
					</div>
				</template>

                <template #cell(no_sts)="data">
                    {{ data.item.no_sts }}<br>
                    {{ data.item.tgl_sts }}
                </template>

                <template #cell(nilai)="data">
                    {{
                        data.item.nilai
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                    }}
                </template>

                <template #cell(status_bayar)="data">
                    <div class="text-center">
                        <div
                            v-if="data.item.status_bayar === 0"
                            class="label label-danger"
                        >
                            Belum Bayar
                        </div>
                        <div v-else class="label label-success">
                            Sudah Bayar
                        </div>
                    </div>
                </template>

                <template v-slot:cell(actions)="data">
                    <b-dropdown
                        split
                        class="d-flex align-item-center"
                        size="sm"
                        variant="info"
                        right
                    >
                        <template #button-content>
                            <i class="fa fa-cogs pr-1"></i> Aksi
                        </template>
                        <b-dropdown-item
                            @click="editButton(data.item.id)"
                            disabled
                            ><i class="fa fa-edit"></i> Edit</b-dropdown-item
                        >
                        <b-dropdown-item
                            @click="swalNotification('error', data.item.id)"
                            disabled
                            ><i class="fa fa-trash-alt"></i>
                            Hapus</b-dropdown-item
                        >
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item @click="showPdf(data.item.no_sts)"
                            ><i class="fa fa-file-pdf"></i>
                            STSRD</b-dropdown-item
                        >
                        <b-dropdown-item @click="showPdf(data.item.no_sts, 3)"
                            ><i class="fa fa-file-pdf"></i>
                            STSRD QRIS STATIS</b-dropdown-item
                        >
                        <b-dropdown-item @click="showPdf(data.item.no_sts, 1)"
                            ><i class="fa fa-file-pdf"></i>
                            STSRD BEND. PENERIMAAN</b-dropdown-item
                        >
                    </b-dropdown>
                </template>
            </b-table>
            <!-- end of Table -->
            <div class="divider"></div>

            <!-- pagination section -->
            <b-row>
                <div class="divider"></div>
                <b-col md="6" class="my-0">
                    <b-form-group :label-cols="2" label="Per page" class="mb-0">
                        <b-form inline>
                            <b-form-select
                                :options="pageOptions"
                                style="width: 100px;"
                                v-model="perPage"
                            />
                            <label class="ml-1 mr-1">/</label>
                            <b-form-input
                                :disabled="true"
                                v-model="totalRows"
                                style="width: 100px;"
                                class="text-right"
                            />
                            <label class="ml-1">Rows</label>
                        </b-form>
                    </b-form-group>
                </b-col>
                <b-col md="6" class="my-0">
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        align="fill"
                        size="md"
                        class="my-0 "
                    ></b-pagination>
                </b-col>
            </b-row>
            <!-- end pagination section -->
            <!-- end of Table -->
        </panel>
        <b-modal
            v-model="showLoader"
            id="modalLoader"
            hide-footer
            hide-header
            no-close-on-backdrop
            centered
            size="sm"
        >
            <div class="text-center">
                <b-spinner variant="light" label="Text Centered"></b-spinner>
                <h5>Harap Menunggu...</h5>
                <div>Data Anda Sedang Di Proses</div>
            </div>
        </b-modal>
    </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars
import HRetribusi from "../../../components/npwr/HRetribusi.vue";
import axios from "axios";
import hapusStorage from '@/helper/hapusLocalStore'

export default {
	mixins: [hapusStorage],
    components: { HRetribusi },
    data() {
        return {
            // Tools
            isBusy: false,
            sortBy: null,
            sortDesc: false,
            filter: null,
            filterBy: "all",
            pageOptions: [5, 10, 15, 25],
            perPage: 10,
            currentPage: 1,
            totalRows: 0,

            // fields
            fields: [
                {
                    key: "index",
                    label: "No.",
					tdClass: 'text-right'
                },
				{
                    key: "spt",
                    label: "PERANGKAT & UNIT",
                    sortable: true,
                },
                {
                    key: "no_sts",
                    label: "No. STS Online",
                    sortable: true,
                },
                {
                    key: "nilai",
                    label: "Nominal STS (Rp.)",
                    sortable: true,
                    tdClass: "text-right",
                },
                // {
                //     key: "status_bayar",
                //     label: "Status STS",
                //     sortable: true,
                // },
                // {
                //     key: "keterangan",
                //     label: "Keterangan",
                //     sortable: true,
                // },
                {
                    key: "actions",
                    label: "Opsi",
                },
            ],
            showModalData: false,
            isLoading: false,
            pdfUrl: "",
            showLoader: false
        };
    },
    computed: {
        fieldOptions() {
            // Create an options list from our fields
            return this.fields
                .filter((f) => f.sortable)
                .map((f) => {
                    return {
                        text: f.label,
                        value: f.key,
                    };
                });
        },
    },
    methods: {
        // data table
        myGetData(ctx) {
            // ctx =
            // berasal dari tag <b-table></b-table>
            //this.$Progress.start();
            this.isBusy = true;
            let promise = axios.get("/api/transaksi/sts", {
                params: {
                    page: ctx.currentPage,
                    perpage: ctx.perPage,
                    sortby: ctx.sortBy,
                    sortdesc: ctx.sortDesc,
                    filter: ctx.filter,
                    filterby: this.filterBy,
                },
            });
            return promise
                .then((response) => {
                    const items = response.data.data;
                    // Data Pagination
                    // configPagination(response.data.meta);
                    this.totalRows = response.data.meta.total;
                    // Here we could override the busy state, setting isBusy to false
                    //this.$Progress.finish();
                    this.isBusy = false;
                    return items;
                })
                .catch((error) => {
                    // Here we could override the busy state, setting isBusy to false
                    //this.$Progress.fail();
                    this.isBusy = false;
					if (error.response.status === 401) {
						// hapus storage mixins
						this.clearAll()
               		}
                    // Returning an empty array, allows table to correctly handle
                    // internal busy state in case of error
                    return [];
                });
        },
        reload() {
            // with id="masTable" in tag b-table
            // this.$root.$emit('bv::refresh::table', 'masTable')
            // with property ref="masTable" in tag b-table
            this.$refs.masTable.refresh();
        },
        changeFilterBy() {
            if (this.filter) {
                this.$refs.masTable.refresh();
            }
        },
        swalNotification(swalType, id) {
            var btnClass = swalType == "error" ? "danger" : swalType;
            btnClass = swalType == "question" ? "primary" : btnClass;
            this.$swal({
                title: "Anda yakin ?",
                text: "Anda tidak akan bisa mengembalikan data!",
                type: swalType,
                showCancelButton: true,
                buttonsStyling: false,
                confirmButtonText: "Hapus",
                cancelButtonText: "Cancel",
                confirmButtonClass: "btn m-r-5 btn-" + btnClass + "",
                cancelButtonClass: "btn btn-default",
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .delete("/api/transaksi/sts/" + id)
                        .then((response) => {
                            this.$swal({
                                title: "Data Berhasil Dihapus!",
                                icon: "success",
                                type: "success",
                                showCloseButton: true,
                                showConfirmButton: false,
                                timer: 1800,
                            });
                            this.reload();
                        });
                }
            });
        },
        editButton(index) {
            this.$router.push({ name: "EditSTSRD", params: { id: index } });
        },
        showPdf(no_sts, metode_bayar_id=null) {
            this.getPdf(no_sts, metode_bayar_id);
        },
        // close modal
        closeModal() {
            this.$root.$emit("bv::hide::modal", "modal-1");
            this.showModalData = false;
            this.pdfUrl = null;
        },
        // view pdf
        getPdf(no_sts, metode_bayar_id) {
            this.showLoader = true
            let pdf_url = null
            let metode_bayar = null
            if (metode_bayar_id) {
                pdf_url = `/api/view-pdf/surat/sts/by-metode-bayar/${no_sts}`
                metode_bayar = metode_bayar_id
            } else {
                pdf_url = `/api/view-pdf/surat/sts/by-sts/${no_sts}`
            }
            axios
                .get(pdf_url, {
                    params: {
                        metode_bayar_id: metode_bayar
                    },
                    responseType: "arraybuffer",
                    timeout: 10000,
                })
                .then((response) => {
                    this.showLoader = false
                    this.pdfUrl = window.URL.createObjectURL(
                        new Blob([response.data], { type: "application/pdf" })
                    );
                    window.open(this.pdfUrl, '_blank')
                })
                .catch((error) => {
                    this.showLoader = false
					if (error.response.status === 401) {
						// hapus storage mixins
						this.clearAll()
					}
                });
        },
    },
};
</script>
